import { Markup } from "interweave";
import React, { useEffect } from "react";

function AlertDismissible(props) {
  const { msgType } = props;

  useEffect(() => {
    const interval = setTimeout(() => {
      props.mySetShow(false);
    }, 3000);

    return () => clearInterval(interval);
  }, [props]);

  return props.myShow && props.myMsg ? (
    <>
      <center
        style={{
          borderRadius: "8px",
          boxShadow:
            " 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05)",
          position: "fixed",
          top: "3%",
          width: "max-content",
          maxWidth: "70%",
          minWidth: "40%",
          margin: "auto",
          left: "0",
          right: "0",
          zIndex: "99999",
        }}
      >
        <div
          className={
            "alert bg-success alert-dismissible text-white fade show text-center shadow"
          }
          role="alert"
          style={{
            color: "#3B71CA",
            fontSize: "16px",
            borderRadius: "8px",
            margin: "0px",
          }}
        >
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => props.mySetShow(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <Markup content={props.myMsg} />
        </div>
      </center>
    </>
  ) : null;
}

export default AlertDismissible;
