import {
  faBook,
  faBuilding,
  faCubes,
  faHome,
  faImage,
  faImages,
  faLink,
  faNewspaper,
  faUserAlt,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "font-awesome/css/font-awesome.min.css";
import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { UserContext } from "../App";
import { hideShow } from "../utils/Helper";

const Sidebar = (props) => {
  const { currentUser } = useContext(UserContext);

  return currentUser ? (
    <>
      <div className="left side-menu" id="sidebar">
        <button
          type="button"
          className="button-menu-mobile button-menu-mobile-topbar open-left waves-effect"
          onClick={() => {
            hideShow();
          }}
        >
          <i className="ion-close" />
        </button>
        <div className="left-side-logo d-block d-lg-none">
          <div className="text-center">
            <a href="index.html" className="logo">
              <img
                className="logo_msbte"
                src="assets/images/Msbte.png"
                height={60}
                alt="Msbte_logo"
              />
            </a>
          </div>
        </div>
        <div className="sidebar-inner slimscrollleft">
          <div id="sidebar-menu">
            <ul className="sidebar_menu">
              <li className="hover_effect">
                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/home" }}
                >
                  <FontAwesomeIcon icon={faHome} />
                  <span className="p_logos ml-2"> Home</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/latestUpdates" }}
                >
                  <FontAwesomeIcon icon={faNewspaper} />
                  <span className="p_logos ml-2"> Add Latest Update</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/importantLinks" }}
                >
                  <FontAwesomeIcon icon={faLink} />
                  <span className="p_logos ml-2"> Add IMP Links</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/photo" }}
                >
                  <FontAwesomeIcon icon={faImage} />
                  <span className="p_logos ml-2"> Add Photos</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/eventPhoto" }}
                >
                  <FontAwesomeIcon icon={faImages} />
                  <span className="p_logos ml-2"> Add Event Photos</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/circular" }}
                >
                  <FontAwesomeIcon icon={faNewspaper} />
                  <span className="p_logos ml-2"> Add Circulars</span>
                </NavLink>
                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/news" }}
                >
                  <FontAwesomeIcon icon={faNewspaper} />
                  <span className="p_logos ml-2"> News/Announcement</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/msbteOfficers" }}
                >
                  <FontAwesomeIcon icon={faBuilding} />
                  <span className="p_logos ml-2"> Add MSBAE Officers</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/eventVideo" }}
                >
                  <FontAwesomeIcon icon={faVideo} />
                  <span className="p_logos ml-2"> Add Event Videos</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/governingCouncil" }}
                >
                  <FontAwesomeIcon icon={faUserAlt} />
                  <span className="p_logos ml-2"> Governing Council</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/governingBoard" }}
                >
                  <FontAwesomeIcon icon={faBook} />
                  <span className="p_logos ml-2"> Governing Board</span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/menu" }}
                >
                  <FontAwesomeIcon icon={faCubes} />
                  <span className="p_logos ml-2"> Menu </span>
                </NavLink>

                <NavLink
                  className="nav-link waves-effect"
                  to={{ pathname: "/regionaloffice" }}
                >
                  <FontAwesomeIcon icon={faBuilding} />
                  <span className="p_logos ml-2"> Regional Officers </span>
                </NavLink>
              </li>

              {/* <li className="has_sub hover_effect">
                  <a href="#" className="waves-effect" onClick={() => {
                    collapseMenu();
                  }}>
                    <FontAwesomeIcon icon={faCubes} />
                    <span className="p_logos ml-2"> Elements </span>{" "}
                    <span className="menu-arrow float-right">
                      <i className="mdi mdi-chevron-right"></i>
                    </span>
                  </a>
                  <ul className="list-unstyled">
                    <li className="hover_effect">
                      <NavLink className="nav-link waves-effect" to={{ pathname: "/home" }} >
                        <FontAwesomeIcon icon={faCubes} />
                        <span className="p_logos ml-2">Link 1</span>
                      </NavLink>
                    </li>
                    <li className="hover_effect">
                      <NavLink className="nav-link waves-effect" to={{ pathname: "/home" }} >
                        <FontAwesomeIcon icon={faCubes} />
                        <span className="p_logos ml-2"> Link 2</span>
                      </NavLink>
                    </li>
                  </ul>
                </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default Sidebar;
