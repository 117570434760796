import React, { useContext, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import Home from "../Components/Admin/AdminHome";
import Circular from "../Components/Admin/Circular";
import EventPhoto from "../Components/Admin/EventPhoto";
import EventVideo from "../Components/Admin/EventVideo";
import GoverningBoard from "../Components/Admin/GoverningBoard";
import GoverningCouncil from "../Components/Admin/GoverningCouncil";
import ImportantLinks from "../Components/Admin/ImportantLinks";
import LatestUpdate from "../Components/Admin/LatestUpdate";
import Menu from "../Components/Admin/Menu";
import News from "../Components/Admin/News";
import Officers from "../Components/Admin/Officer";
import RegionalOffice from "../Components/Admin/RegionalOffice";
import UploadPhoto from "../Components/Admin/UploadPhoto";
import Footer from "./Footer";
import Header from "./Header";
import Sidebar from "./Sidebar";

const Content = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/login");
    }
  });
  return currentUser ? (
    <>
      <Sidebar />
      <div className="content-page">
        <div className="content">
          <Header />
          <div className="page-content-wrapper ">
            <Routes>
              <Route path="/home" element={<Home />} />
              <Route path="/circular" element={<Circular />} />
              <Route path="/latestUpdates" element={<LatestUpdate />} />
              <Route path="/importantLinks" element={<ImportantLinks />} />
              <Route path="/msbteOfficers" element={<Officers />} />
              <Route path="/news" element={<News />} />
              <Route path="/governingCouncil" element={<GoverningCouncil />} />
              <Route path="/governingBoard" element={<GoverningBoard />} />

              <Route path="/photo" element={<UploadPhoto />} />

              {/* <Route path="/photo" element={<UploadPhoto />} /> */}
              <Route path="/eventPhoto" element={<EventPhoto />} />
              <Route path="/eventVideo" element={<EventVideo />} />

              <Route path="/menu" element={<Menu />} />
              <Route path="/regionaloffice" element={<RegionalOffice />} />
            </Routes>
          </div>
        </div>
        <Footer />
      </div>
    </>
  ) : null;
};

export default Content;
