import $ from 'jquery';
import aes from "crypto-js/aes";
import encHex from "crypto-js/enc-hex";
import padZeroPadding from "crypto-js/pad-zeropadding";
import CryptoJS from "crypto-js";
import { S3 } from "aws-sdk";
import axios from 'axios';
import API from '../API';



const calculateFileSHA256Hash = async (file) => {
    // Convert file to array buffer
    const arrayBuffer = await file.arrayBuffer();

    // Calculate SHA-256 hash using SubtleCrypto API
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);

    // Convert hash buffer to hex string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

    return hashHex;
};

const getPresignedUrl = async (file, path) => {

    const hash = await calculateFileSHA256Hash(file);

    const response = await API.post('/generate-presigned-upload-url', {
        key: path,
        content_type: file.type,
        ContentSHA256: hash
    });

    return response.data.url;
};

export const uploadFileToS3UsingSignedURL = async (file, folder) => {
    return uploadFileInChunks(file, folder)
};


export async function uploadFileToS3(file, location) {
    const accessKeyId = ""
    const secretAccessKey = ""

    const s3 = new S3({
        accessKeyId,
        secretAccessKey,
        region: "ap-south-1",
        params: { Bucket: "msbae-assets" },
    });


    const params = {
        Bucket: "msbae-assets",
        Key: `${location}/${file.name}`,
        Body: file,
        ACL: "public-read",
        ContentType: file.type,
    };

    // Upload the file to S3 using a Promise
    try {
        const data = await s3.upload(params).promise(); // Using promise()
        console.log("File uploaded successfully:", data.Location);
        return data.Location;
    } catch (err) {
        console.error("Error uploading file:", err);
    }
}


export const uploadFileInChunks = async (file, storagePath, setProgress) => {
    if (!file) return;

    const chunkSize = 512 * 1024;
    const totalChunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    let timestamp = Date.now();


    while (currentChunk < totalChunks) {
        const start = currentChunk * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);

        const formData = new FormData();
        formData.append("file", chunk);
        formData.append("chunk", currentChunk);
        formData.append("totalChunks", totalChunks);
        formData.append("filename", file.name);
        formData.append("timestamp", timestamp);
        formData.append("storagePath", storagePath);

        try {
            const res = await API.post("/upload-chunk", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                // eslint-disable-next-line no-loop-func
                onUploadProgress: (progressEvent) => {
                    if (progressEvent.total) {
                        // setProgress((prev) => Math.min((currentChunk / totalChunks) * 100, 100));
                    }
                },
            });
            if (res.data?.filePath) {
                return process.env.REACT_APP_DATA_URL + res.data?.filePath
            }
        } catch (error) {
            console.error("Error uploading chunk", error);
            break;
        }

        currentChunk++;
    }

    console.log("File uploaded successfully!");
};


export function hideShow() {
    let body = document.getElementById('body');
    let wrapper = document.getElementById('wrapper');
    if (body.className.indexOf('fixed-left-void') !== -1) {
        body.classList.remove('fixed-left-void');
        wrapper.classList.remove('enlarged');
    }
    else {
        body.classList.add('fixed-left-void');
        wrapper.classList.add('enlarged');
    }
}

export function hide() {
    let body = document.getElementById('body');
    let wrapper = document.getElementById('wrapper');
    body.classList.add('fixed-left-void');
    wrapper.classList.add('enlarged');
}

export function collapseMenu() {
    $('.has_sub').each(function () {
        var t = $(this);
        if (t.hasClass('nav-active')) {
            t.find('> ul').slideUp(300, function () {
                t.removeClass('nav-active');
            });
        }
        else {
            t.find('> ul').slideDown(300, function () {
                t.addClass('nav-active');
            });
        }
    });
}

export function searchStringInArray(str, strArray) {
    for (var j = 0; j < strArray.length; j++) {
        if (strArray[j].match(str)) return j;
    }
    return -1;
}

export function en(data) {
    try {
        let text =
            data !== undefined && data !== null && data !== "" ? "" + data + "" : "";
        if (text === "") {
            return text;
        }

        let key = encHex.parse(process.env.REACT_APP_EN_ID1);
        let iv = encHex.parse(process.env.REACT_APP_EN_ID2);
        let en1 = aes
            .encrypt(text, key, { iv: iv, padding: padZeroPadding })
            .toString();

        return en1;
    } catch (e) {
        return "";
    }
}

export function de(data) {
    try {
        let encrypted =
            data !== undefined && data !== null && data !== "" ? data : "";
        if (encrypted === "") {
            return encrypted;
        }

        let key = encHex.parse(process.env.REACT_APP_EN_ID1);
        let iv = encHex.parse(process.env.REACT_APP_EN_ID2);
        let de1 = aes
            .decrypt(encrypted, key, { iv: iv })
            .toString(CryptoJS.enc.Utf8);

        return de1;
    } catch (e) {
        return "";
    }
}

export function rtrim(str, chr) {
    var rgxtrim = !chr ? new RegExp("\\s+$") : new RegExp(chr + "+$");
    return str.replace(rgxtrim, "");
}

export function ltrim(str, chr) {
    var rgxtrim = !chr ? new RegExp("^\\s+") : new RegExp("^" + chr + "+");
    return str.replace(rgxtrim, "");
}

export function getFullScreenElement() {
    return document.getFullScreenElement || document.webkitFullscreenElement || document.mozFullscreenElement || document.msFullscreenElement;
}

